/* GOPStateSelector.module.css */
.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
    min-width: 200px;
    max-width: 250px;
}
